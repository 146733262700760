<template>
  <div class="app-container">

    <el-card class="box-card">
      <div v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
        <div slot="header" class="clearfix">
          <span>{{ $t('categorie.categories') }}</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
        </div>
        <div class="filter-container">
          <el-radio v-model="activeList" label="actives" border @change="getSubCategoriesList('indexactive')">{{ $t('general.F_actives') }}</el-radio>
          <el-radio v-model="activeList" label="desactives" border @change="getSubCategoriesList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
          <el-radio v-model="activeList" label="disponibles" border @change="handleFilterCategories">{{ $t('general.disponibles') }}</el-radio>
          <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubCategoriesList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
        </div>
      </div>
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterCategories" />
        <!--el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterCategories">
          {{ $t('general.search') }}
        </el-button-->
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['create categorie'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreateCategorie">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">

        <el-table-column align="center" label="" width="100">
          <template slot-scope="scope">
            <div class="demo-basic--circle">
              <div>
                <el-avatar shape="square" :size="50" :src="scope.row.image_uri" />
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('categorie.label')">
          <template slot-scope="scope">
            <span>{{ scope.row.label }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('categorie.description')">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('categorie.produitCount')" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.articles.length ? scope.row.articles.length : 0 }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="activeList!='corbeille' && checkPermission(['activate categorie'])" align="center" :label="$t('general.status')" width="80">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setCategorieStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="activeList=='corbeille' && checkRole(['admin'])" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setCategorieStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>
        <el-table-column v-if="checkPermission(['edit categorie','hide categorie','delete categorie'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="150">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['edit categorie'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditCategorie(scope.row.id);" />
            <el-button v-if="checkPermission(['hide categorie','delete categorie'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteCategorie(scope.row.id, scope.row.is_visible, scope.row.label);" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getCategoriesList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('categorie.edit') + ' ' + currentCategorie.label : $t('categorie.AddCategorie')"
        :visible.sync="dialogCreateCategorie"
        :before-close="handleCloseCategorieCreateDraw"
        :loading="categorieCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="categorieForm" :rules="rules" :model="currentCategorie" label-position="left">
            <el-form-item :label="$t('categorie.label')" prop="label">
              <el-input v-model="currentCategorie.label" />
            </el-form-item>
            <el-form-item prop="image_uri" class="w100" style="margin-bottom: 30px;">
              <Upload
                v-model="currentCategorie.image_uri"
                :uploadurl="thumbPostUrl"
              />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentCategorie.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateCategorie=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="categorieCreating" @click="editing ? updateCategorie() : createCategorie()">
              {{ categorieCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Upload from '@/components/Upload/SingleImage';
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const categorieResource = new Resource('categories');
export default {
  name: 'CategoriesList',
  components: { Pagination, Upload },
  directives: { waves, permission, role },
  data() {
    return {
      thumbPostUrl: this.$appbaseUrl + 'upload.php',
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      categorieCreating: false,
      editing: false,
      activeList: 'disponibles',
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
      currentCategorie: {},
      dialogCreateCategorie: false,
      currentCategorieId: 0,
      rules: {
        label: [{ required: true, message: this.$t('categorie.LabelRequired'), trigger: 'blur' }],
        description: [{ required: true, message: this.$t('categorie.DescriptionRequired'), trigger: 'blur' }],
      },
    };
  },
  computed: {
  },
  created() {
    this.resetNewCategorie();
    this.getCategoriesList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCategorieCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelCategorieForm() {
      this.$refs.CategorieCreationDrawer.closeDrawer();
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
      };
      this.activeList = 'disponibles';
      this.handleFilterCategories();
    },
    async getCategoriesList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await categorieResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
    },
    async getSubCategoriesList(subUrl) {
      this.loading = true;
      const { data } = await categorieResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterCategories() {
      this.query.page = 1;
      this.getCategoriesList();
    },
    handleCreateCategorie() {
      this.editing = false;
      this.resetNewCategorie();
      this.dialogCreateCategorie = true;
      this.$nextTick(() => {
        this.$refs['categorieForm'].clearValidate();
      });
    },
    handleEditCategorie(id){
      this.editing = true;
      this.resetNewCategorie();
      this.currentCategorie = this.list.find(abonne => abonne.id === id);
      this.dialogCreateCategorie = true;
      this.$nextTick(() => {
        this.$refs['categorieForm'].clearValidate();
      });
    },
    async setCategorieStatus(id, type){
      const data = await categorieResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteCategorie(id, visible, name) {
      this.$confirm(this.$t('categorie.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['admin']) || checkPermission(['delete categorie']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              categorieResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('categorie.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setCategorieStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createCategorie() {
      this.$refs['categorieForm'].validate((valid) => {
        if (valid) {
          this.currentCategorie.roles = [this.currentCategorie.role];
          this.categorieCreating = true;
          categorieResource
            .store(this.currentCategorie)
            .then(async(response) => {
              this.$message({
                message: this.$t('categorie.NewCategorie') + ' ' + this.currentCategorie.label + ' ' + this.$t('categorie.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentCategorieId = response.data.id;
              this.resetNewCategorie();
              this.dialogCreateCategorie = false;
              this.handleFilterCategories();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.categorieCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    updateCategorie() {
      this.$refs['categorieForm'].validate((valid) => {
        if (valid) {
          this.categorieCreating = true;
          categorieResource.update(this.currentCategorie.id, this.currentCategorie).then(response => {
            this.resetNewCategorie();
            this.handleFilterCategories();
            this.$message({
              message: this.$t('categorie.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateCategorie = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.categorieCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewCategorie() {
      this.currentCategorie = {
        label: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('categorie.label'), this.$t('general.description')];
        const filterVal = ['id', 'label', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des régions SOYCAIN_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
